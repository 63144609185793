import { AutoAwesome, VolumeUp } from '@mui/icons-material';
import { Box, FormControl, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import useIsMobile from '../../constants/useIsMobile';
import Char from '../../generals/Char';
import { TTS } from '../../generals/speak';
import { QuestionModel } from '../../models/QuestionModel';
import AnswerInfoResponse from '../../responses/AnswerInfo';
import EmptyResponse from '../../responses/EmptyResponse';
import EmptyResponseType from '../../responses/EmptyResponseType';
import { AnswerInfo2EndpointGetHook, AnswerInfo2EndpointGetPromise } from '../../routes/AnswerInfo';
import AnswerButton from '../AnswerButton';
import QuestionText from './QuestionText';
import SelectableChips from './SelectableChips';
import SelectedChips from './SelectedChips';

interface ForcedQuestion extends QuestionModel {
    syllables: any[];
    question: string;
    answer: string;
    parts: string;
}

interface Props {
    question: ForcedQuestion;
    index: number;
    answer: string;
    answerTransliteration?: string;
    answerDisabledState: boolean;
    setCurrentAnswer: (answer: string) => void;
    handleAnswer: () => void;
    handleAddChip: (id: string) => void;
    handleDeleteChip: (id: string) => void;
    setSelectedChips: (chips: string[]) => void;
    selectedChips: string[];
    chipOrTextState: string;
    setChipOrTextState: any;
    baseLanguage: string;
    targetLanguage: string;
    convertChipToSyllable: (id: string) => string;
    contentPosition: any;
    translations: any;
    checkCorrect?: (answer: string) => Promise<boolean>;
    lection: string;
}

function ForcedQuestion(props: Props) {
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const isMobile = useIsMobile();

    const [isAnswered, setIsAnswered] = useState<boolean>(false);
    useEffect(() => {
        setIsAnswered(false);
    }, [props.question]);

    const handleAnswer = () => {
        setIsAnswered(true);
        props.handleAnswer();
    };

    useEffect(() => {
        if (props.checkCorrect) {
            props.checkCorrect(props.answer).then(result => {
                setIsCorrect(result);
            });
        } else {
            setIsCorrect(false);
        }
    }, [props.checkCorrect, props.answer]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (props.checkCorrect) {
                props.checkCorrect(props.answer).then(result => {
                    setIsCorrect(result);
                });
            } else {
                setIsCorrect(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [props.checkCorrect, props.answer]);

    const isNextChip = (index: number) => {
        const currentCount = props.selectedChips.length;

        return index === currentCount;
    };

    const [answerInfoState, setAnswerInfoState] = useState<AnswerInfoResponse | EmptyResponseType>(EmptyResponse);

    const answerInfoResponse = AnswerInfo2EndpointGetHook(props.question.id);

    useEffect(() => {
        if (answerInfoResponse.data) {
            setAnswerInfoState(answerInfoResponse);
        }
    }, [answerInfoResponse.data]);

    useEffect(() => {
        const promise = AnswerInfo2EndpointGetPromise(props.question.id);

        promise.then((response) => {
            setAnswerInfoState(response);
        });
    }, [props.question.id]);

    const removeFirstTwoLines = (text: string) => {
        const lines = text.split('\n');

        if (lines[0].startsWith('#') && lines.length > 2) {
            return text.split('\n').slice(2).join('\n');
        }

        return text;
    };

    const getIsExpanded = () => {
        if (localStorage.getItem('infoExpanded') === 'false') {
            return false;
        }

        localStorage.setItem('infoExpanded', 'true');

        return true;
    };

    const switchExpanded = () => {
        if (localStorage.getItem('infoExpanded') === 'true') {
            localStorage.setItem('infoExpanded', 'false');
        } else {
            localStorage.setItem('infoExpanded', 'true');
        }
    };

    const getFirstFourWords = (text: string) => text.split(' ').slice(0, 4).join(' ');

    const isQuestionMultipleWords = () => props.question.question.split(' ').length > 1;

    return (
        <Box
            sx={props.contentPosition}
        >
            {answerInfoState.status === 200 && answerInfoState.data.question === props.question.id && !isMobile &&
                <Box
                    sx={{
                        position: 'absolute',
                        right: 35,
                        top: 50,
                        width: '16vw',
                        backgroundColor: useTheme().palette.grey[100],
                        borderRadius: 2,
                        textAlign: 'left',
                        boxShadow: 0,
                        px: 2,
                    }}
                >
                    <ReactMarkdown>
                        {removeFirstTwoLines(answerInfoState.data.info)}
                    </ReactMarkdown>
                </Box>
            }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    width: '100%',
                }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    fontWeight='bold'
                    sx={{
                        color: useTheme().palette.success.main,
                    }}
                >
                    <AutoAwesome
                        sx={{
                            fontSize: 14,
                            mr: 1,
                        }}
                    />
                    {isQuestionMultipleWords() ? props.translations.newPhrase : props.translations.newWord}
                </Typography>
                <Typography
                    variant="h4"
                    gutterBottom
                    fontWeight='bold'
                    color='text.primary'
                >
                    {props.translations.translation}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Char
                        question={props.question}
                        language={props.targetLanguage}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '0.1px solid #c4c4c4',
                            borderRadius: 4,
                            backgroundColor: useTheme().palette.grey[50],
                            paddingY: 2,
                            paddingLeft: 2,
                            paddingRight: 4,
                            ml: 2,
                            // Add a triangle to the left of the box
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                left: '-10px',
                                top: '25%',
                                width: 0,
                                height: 0,
                                borderStyle: 'solid',
                                borderWidth: '10px 10px 10px 0',
                                borderColor: 'transparent ' + useTheme().palette.grey[200] + ' transparent transparent',
                                transform: 'translateY(-50%)',
                            },
                        }}
                    >
                        <IconButton
                            onClick={
                                () => { TTS(props.question.question, props.baseLanguage); }
                            }
                            color='primary'
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <VolumeUp />
                        </IconButton>
                        <QuestionText
                            question={props.question.question}
                            questionId={props.question.id}
                            lection={props.lection}
                            direction='target'
                            entireTranslation={props.question.answer}
                            entireTransliteration={props.answerTransliteration}
                            baseLanguage={props.targetLanguage}
                            language={props.baseLanguage}
                            showTranslation={isAnswered}
                            parts={props.question.parts}
                        />
                    </Box>
                </Box>
            </Box>
            {answerInfoState.status === 200 && answerInfoState.data.question === props.question.id && isMobile &&
                <Box
                    sx={{
                        width: '100%',
                        boxSizing: 'border-box',
                        backgroundColor: useTheme().palette.grey[100],
                        borderRadius: 2,
                        maxHeight: '10vh',
                        overflowY: 'auto',
                        marginTop: 2,
                        padding: 1,
                    }}
                >
                    <Typography
                        variant="body1"
                        gutterBottom
                        fontSize={12}
                        sx={{
                            textAlign: 'left',
                            overflowY: 'auto',
                            maxHeight: 100,
                        }}
                    >
                        <ReactMarkdown>
                            {removeFirstTwoLines(answerInfoState.data.info)}
                        </ReactMarkdown>
                    </Typography>
                </Box>
            }
            <FormControl fullWidth>
                {props.chipOrTextState === 'chip' &&
                    <SelectedChips
                        selectedChips={props.selectedChips}
                        convertChipToSyllable={props.convertChipToSyllable}
                        setSelectedChips={props.setSelectedChips}
                        draggable={false}
                        handleAddChip={props.handleAddChip}
                        showPinYin={props.targetLanguage === 'chinese'}
                        language={props.targetLanguage}
                    />
                }
                {props.chipOrTextState === 'text' &&
                    <TextField
                        fullWidth
                        variant='standard'
                        label={props.translations.answer}
                        value={props.answer}
                        onChange={(event) => props.setCurrentAnswer(event.target.value)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottom: '1px solid ' + useTheme().palette.divider,
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottom: '1px solid ' + useTheme().palette.divider,
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: '1px solid ' + useTheme().palette.divider,
                            },
                            marginTop: 2,
                            '& .MuiInputBase-input': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                }
            </FormControl>
            <FormControl fullWidth>
                {props.chipOrTextState === 'chip' &&
                    <SelectableChips
                        handleDeleteChip={props.handleDeleteChip}
                        handleAddChip={props.handleAddChip}
                        selectedChips={props.selectedChips}
                        question={props.question}
                        answerDisabledState={props.answerDisabledState}
                        forceNextChip={true}
                        showPinYin={props.targetLanguage === 'chinese'}
                        language={props.targetLanguage}
                    />
                }
            </FormControl>
            <AnswerButton
                answer={handleAnswer}
                currentAnswer={props.answer}
                correctAnswer={props.question.answer}
                translations={props.translations}
                baseLanguage={props.baseLanguage}
                targetLanguage={props.targetLanguage}
                speak={TTS}
                disabledOverride={isCorrect}
            />
        </Box>
    );
}

export default ForcedQuestion;
