export const gettheme = () => {
    const systemPrefers = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const darkMode = localStorage.getItem('darkMode') ?? (systemPrefers ? '1' : '0');

    if (localStorage.getItem('darkMode') === null) {
        localStorage.setItem('darkMode', systemPrefers ? '1' : '0');
    }

    return darkMode === '1';
};

export const changetheme = () => {
    const darkMode = localStorage.getItem('darkMode') === '1' ? '0' : '1';
    localStorage.setItem('darkMode', darkMode);

    window.location.reload();
};
