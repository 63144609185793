import { AppBar, Badge, BottomNavigation, BottomNavigationAction, Box, Button, FormControl, IconButton, MenuItem, Select, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Language2EndpointGetHook } from '../routes/Language';
import EmptyResponseType from '../responses/EmptyResponseType';
import EmptyResponse from '../responses/EmptyResponse';
import { AutoGraph, ChatOutlined, ChatRounded, DarkModeRounded, Home, HomeOutlined, HomeRounded, Leaderboard, LeaderboardOutlined, LeaderboardRounded, Person, PersonOutline, PersonOutlined, Settings, SettingsOutlined, SpatialAudio, SpatialAudioOutlined, SpatialAudioRounded, StickyNote2, StickyNote2Outlined, StickyNote2Rounded, WbSunnyRounded, Whatshot } from '@mui/icons-material';
import { getCurrentPage } from '../modules/api/client';
import ExperienceResponse from '../responses/Experience';
import { Experience2EndpointGetHook, Experience2EndpointGetPromise } from '../routes/Experience';
import StreakResponse from '../responses/Streak';
import { Streak2EndpointGetHook, Streak2EndpointGetPromise } from '../routes/Streak';
import PhasesCountResponse from '../responses/PhasesCount';
import { PhasesCount2EndpointGetHook, PhasesCount2EndpointGetPromise } from '../routes/PhasesCount';
import CoursesResponse from '../responses/Courses';
import { Courses2EndpointGetHook } from '../routes/Courses';
import BetterCourseSwitcher from '../courses/BetterCourseSwitcher';
import useEndpoint from './useEndpoint';
import LectionsResponse from '../responses/Lections';
import { Lections2EndpointGetHook } from '../routes/Lections';
import CreateCourse from './CreateCourse';
import NewLoginButton from './NewLoginButton';
import useIsLgOrLess from './isLg';
import { changetheme, gettheme } from './gettheme';
import { HasSetLanguage2EndpointGetHook, HasSetLanguage2EndpointPostPromise } from '../routes/HasSetLanguage';

interface Props {
    children: React.ReactNode;
    noMenu?: boolean;
}

export default function Website(props: Props) {
    const navigate = useNavigate();
    const isLgOrLess = useIsLgOrLess();

    const [translations, setTranslations] = useState({
        signup: '',
        lections: '',
        vocab: '',
        grammar: '',
        shop: '',
        alphabet: '',
        letters: '',
        admin: '',
        chatbots: '',
        logout: '',
        profile: '',
        login: '',
        email: '',
        resendToken: '',
        onetimepin: '',
        leaderboard: '',
        loginToExistingAccount: '',
        lb: '',
        chats: '',
        english: '',
        german: '',
        spanish: '',
        italian: '',
        dutch: '',
        portuguese: '',
        french: '',
    });

    const hasSetLanguageResponse = HasSetLanguage2EndpointGetHook('');

    useEffect(() => {
        if (typeof hasSetLanguageResponse.data !== 'undefined') {
            if (hasSetLanguageResponse.data.hasSetLanguage === false) {
                const browserLanguage = navigator.language;
                const expectedLanguage = hasSetLanguageResponse.data.expectedLanguage;

                const promise = HasSetLanguage2EndpointPostPromise(browserLanguage);

                promise.then((response) => {
                    if (response.status === 200) {
                        if (response.data.expectedLanguage !== expectedLanguage) {
                            navigate(0);
                        }
                    }
                });
            }
        }
    }, [hasSetLanguageResponse.data]);

    const [language, setLanguage] = useState('');
    const [languageState, setLanguageState] = useState('');

    useEffect(() => {
        if (language === '' || language === languageState) {
            return;
        }

        const promise = HasSetLanguage2EndpointPostPromise(language);

        promise.then(() => {
            navigate(0);
        });
    }, [language]);

    useEffect(() => {
        const darkMode = gettheme();

        let themeColorMeta = document.querySelector('meta[name="theme-color"]');
        if (!themeColorMeta) {
            themeColorMeta = document.createElement('meta');
            themeColorMeta.setAttribute('name', 'theme-color');
            document.head.appendChild(themeColorMeta);
        }

        let statusBarMeta = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
        if (!statusBarMeta) {
            statusBarMeta = document.createElement('meta');
            statusBarMeta.setAttribute('name', 'apple-mobile-web-app-status-bar-style');
            document.head.appendChild(statusBarMeta);
        }

        if (darkMode) {
            themeColorMeta.setAttribute('content', '#000000'); // Dark theme color
            statusBarMeta.setAttribute('content', 'black-translucent');
        } else {
            themeColorMeta.setAttribute('content', '#ffffff'); // Light theme color
            statusBarMeta.setAttribute('content', 'default');
        }
    }, []);

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    const [isLogin, setIsLogin] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (translationsResponse.status === 200 && translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
            setLanguageState(translationsResponse.data.translations.userLanguageSetting);
            setLanguage(translationsResponse.data.translations.userLanguageSetting);
            setIsLogin(translationsResponse.userState.isLogin);
            setIsAdmin(translationsResponse.userState.userModel.role === 'admin');
        }
    }, [translationsResponse.data]);

    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    const course = localStorage.getItem('course') || '';

    const setCourse = (course: string) => {
        localStorage.setItem('course', course);
        window.location.href = '/home';
    };

    const [lectionsState, setLectionsState] = useEndpoint<LectionsResponse>(Lections2EndpointGetHook(course));

    const [coursesState, setCoursesState] = useState<CoursesResponse | EmptyResponseType>(EmptyResponse);

    const coursesResponse = Courses2EndpointGetHook('');

    useEffect(() => {
        if (coursesResponse.data) {
            setCoursesState(coursesResponse);
        }
    }, [coursesResponse.data]);

    const translateCourseIntoBaseLanguage = (course: any) => {
        const split = course.split('__-__');
        return split[0];
    };

    const translateCourseIntoTargetLanguage = (course: any) => {
        const split = course.split('__-__');
        return split[1];
    };

    const [phasesCountState, setPhasesCountState] = useState<PhasesCountResponse | EmptyResponseType>(EmptyResponse);

    const phasesCountResponse = PhasesCount2EndpointGetHook(translateCourseIntoBaseLanguage(course), translateCourseIntoTargetLanguage(course));

    useEffect(() => {
        if (phasesCountResponse.data) {
            setPhasesCountState(phasesCountResponse);
        }
    }, [phasesCountResponse.data]);

    const [initialExperience, setInitialExperience] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await PhasesCount2EndpointGetPromise(translateCourseIntoBaseLanguage(course), translateCourseIntoTargetLanguage(course));
                setPhasesCountState(response);
            } catch (error) {
                console.error(error);
            }
        };

        const intervalId = setInterval(fetchData, 5000);

        fetchData();

        return () => {
            clearInterval(intervalId);
        };
    }, [course]);

    const [streakState, setStreakState] = useState<StreakResponse | EmptyResponseType>(EmptyResponse);

    const streakResponse = Streak2EndpointGetHook();

    useEffect(() => {
        if (streakResponse.data) {
            setStreakState(streakResponse);
        }
    }, [streakResponse.data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Streak2EndpointGetPromise();
                setStreakState(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const intervalId = setInterval(fetchData, 50000);

        fetchData();

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const [experienceState, setExperienceState] = useState<ExperienceResponse | EmptyResponseType>(EmptyResponse);

    const experienceResponse = Experience2EndpointGetHook();

    useEffect(() => {
        if (experienceResponse.data) {
            setExperienceState(experienceResponse);
            setInitialExperience(experienceResponse.data.experience);
        }
    }, [experienceResponse.data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Experience2EndpointGetPromise();
                setExperienceState(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const intervalId = setInterval(fetchData, 5000);

        fetchData();

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const themeFromThemeProvider = useTheme();

    return (
        <div
            style={{
                minHeight: '100vh',
                maxWidth: '100vw',
                width: '100%',
                backgroundColor: useTheme().palette.background.default,
                color: useTheme().palette.text.primary,
            }}
        >
            {!isMobile && !props.noMenu &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: 7.5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        zIndex: 1000,
                    }}
                >
                    <AppBar
                        position="relative"
                        elevation={0}
                        sx={{
                            backgroundColor: useTheme().palette.primary.dark + (gettheme() ? '50' : '10'),
                            backdropFilter: 'blur(15px)',
                            width: '80%',
                            // Set minWidth to whatever is smaller: 1100 or 80% of the screen width
                            minWidth: 'calc(min(1100px, 90vw))',
                            maxWidth: '99vw',
                            borderRadius: 25,
                        }}
                    >
                        <Toolbar
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '1px solid rgba(0, 0, 0, 0.18)',
                                boxShadow: 0,
                                borderRadius: 25,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    color: useTheme().palette.text.primary,
                                }}
                                onClick={() => { navigate('/home'); }}
                            >
                                <img
                                    src="/logo.jpeg"
                                    alt="logo"
                                    style={{
                                        width: 30,
                                        height: 30,
                                        marginRight: 10,
                                        borderRadius: 50,
                                    }}
                                />
                                {!isLgOrLess &&
                                    'lairner.app'
                                }
                            </Box>
                            <Box
                                sx={{
                                    marginLeft: 'auto',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                            >
                                {isLogin && coursesState.status === 200 && coursesState.data.courses.length > 0 &&
                                    <>
                                        {isLogin && isAdmin && !isLgOrLess &&
                                            <CreateCourse
                                            />
                                        }
                                        {isAdmin && !isLgOrLess &&
                                            <FormControl>
                                                <IconButton
                                                    onClick={() => {
                                                        navigate('/admin/overview');
                                                    }}
                                                    sx={{
                                                        marginRight: 5,
                                                    }}
                                                >
                                                    {getCurrentPage() === 'admin'
                                                        ? <Settings
                                                            sx={{
                                                                fontSize: 15,
                                                            }}
                                                        />
                                                        : <SettingsOutlined
                                                            sx={{
                                                                fontSize: 15,
                                                            }}
                                                        />
                                                    }
                                                </IconButton>
                                            </FormControl>
                                        }
                                        <Button
                                            onClick={() => {
                                                navigate('/home');
                                            }}
                                            sx={{
                                                mr: 1,
                                                color: useTheme().palette.text.primary,
                                            }}
                                        >
                                            {getCurrentPage() === 'lections' ? <HomeRounded sx={{ marginRight: 1 }} /> : <HomeOutlined sx={{ marginRight: 1 }} />}
                                            {translations.lections}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                navigate('/vocab/' + translateCourseIntoBaseLanguage(course) + '/' + translateCourseIntoTargetLanguage(course));
                                            }}
                                            sx={{
                                                mr: 1,
                                                color: useTheme().palette.text.primary,
                                            }}
                                        >
                                            {getCurrentPage() === 'vocab' ? <StickyNote2Rounded sx={{ marginRight: 1 }} /> : <StickyNote2Outlined sx={{ marginRight: 1 }} />}
                                            {translations.vocab}
                                            {phasesCountState.data.dueCount > 0 &&
                                                <Badge
                                                    badgeContent={phasesCountState.data.dueCount}
                                                    color="error"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 5,
                                                        right: 5,
                                                    }}
                                                />
                                            }
                                        </Button>
                                        {lectionsState.status === 200 && lectionsState.data.letters.length > 0 &&
                                            <Button
                                                onClick={() => {
                                                    navigate('/alphabet');
                                                }}
                                                sx={{
                                                    mr: 1,
                                                    color: useTheme().palette.text.primary,
                                                }}
                                            >
                                                {getCurrentPage() === 'alphabet' ? <SpatialAudioRounded sx={{ marginRight: 1 }} /> : <SpatialAudioOutlined sx={{ marginRight: 1 }} />}
                                                {translations.alphabet}
                                            </Button>
                                        }
                                        {experienceState.status === 200 && experienceState.data.personalLeaderboard.xp > 0 &&
                                            <Button
                                                onClick={() => {
                                                    navigate('/leaderboard');
                                                }}
                                                sx={{
                                                    color: useTheme().palette.text.primary,
                                                }}
                                            >
                                                {getCurrentPage() === 'leaderboard' ? <LeaderboardRounded sx={{ marginRight: 1 }} /> : <LeaderboardOutlined sx={{ marginRight: 1 }} />}
                                                {isLgOrLess ? translations.lb : translations.leaderboard}
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => {
                                                navigate('/chatbots');
                                            }}
                                            sx={{
                                                color: useTheme().palette.text.primary,
                                            }}
                                        >
                                            {getCurrentPage() === 'chatbots' ? <ChatRounded sx={{ marginRight: 1 }} /> : <ChatOutlined sx={{ marginRight: 1 }} />}
                                            {isLgOrLess ? translations.chats : translations.chatbots}
                                        </Button>
                                        {streakState.status === 200 &&
                                            <Button
                                                variant="text"
                                                sx={{
                                                    marginLeft: 4,
                                                    color: streakState.data.streak > 0 ? 'primary.dark' : useTheme().palette.text.primary,
                                                }}
                                            >
                                                <Whatshot
                                                    sx={{
                                                        marginRight: 1,
                                                    }}
                                                />
                                                {streakState.data.streak}
                                            </Button>
                                        }
                                        {experienceState.status === 200 && experienceState.data.experience > 0 &&
                                            <Button
                                                sx={{
                                                    color: experienceState.data.experience > initialExperience ? 'primary.dark' : useTheme().palette.text.primary,
                                                }}
                                                onClick={() => {
                                                    navigate('/leaderboard');
                                                }}
                                            >
                                                <AutoGraph
                                                    sx={{
                                                        marginRight: 1,
                                                    }}
                                                />
                                                {experienceState.data.experience}
                                            </Button>
                                        }
                                        {isLogin &&
                                            <IconButton
                                                onClick={() => {
                                                    changetheme();
                                                }}
                                                sx={{
                                                    color: useTheme().palette.text.primary,
                                                    mx: 1,
                                                }}
                                            >
                                                {gettheme()
                                                    ? <DarkModeRounded
                                                        sx={{
                                                            fontSize: 12,
                                                        }}
                                                    />
                                                    : <WbSunnyRounded
                                                        sx={{
                                                            fontSize: 12,
                                                        }}
                                                    />
                                                }
                                            </IconButton>
                                        }
                                        {isLogin &&
                                            <IconButton
                                                sx={{
                                                    color: getCurrentPage() === 'profile' ? 'primary.dark' : useTheme().palette.text.primary,
                                                    border: '1px solid #c0c0c0',
                                                    mr: 1,
                                                }}
                                            >
                                                <PersonOutline
                                                />
                                            </IconButton>
                                        }
                                        <BetterCourseSwitcher
                                            course={course}
                                            setCourse={setCourse}
                                        />
                                    </>
                                }
                                {!isLogin &&
                                    <Select
                                        variant="standard"
                                        value={language}
                                        onChange={(event) => setLanguage(event.target.value)}
                                        sx={{
                                            mx: 2,
                                        }}
                                    >
                                        <MenuItem value="english">English</MenuItem>
                                        <MenuItem value="german">Deutsch</MenuItem>
                                        <MenuItem value="french">Français</MenuItem>
                                        <MenuItem value="spanish">Español</MenuItem>
                                        <MenuItem value="italian">Italiano</MenuItem>
                                        <MenuItem value="dutch">Nederlands</MenuItem>
                                        <MenuItem value="portuguese">Português</MenuItem>
                                    </Select>
                                }
                                {experienceState.status !== 102 && experienceState.userState.isLogin === false &&
                                    <NewLoginButton
                                        translations={translations}
                                    />
                                }
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            }
            {isMobile && !props.noMenu &&
                <AppBar
                    position="sticky"
                    elevation={0}
                    sx={{
                        backgroundColor: useTheme().palette.primary.dark + (gettheme() ? '50' : '20'),
                        backdropFilter: 'blur(15px)',
                    }}
                >
                    <Toolbar
                        sx={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '0.1px solid #c0c0c0',
                        }}
                    >
                        <img
                            onClick={() => { navigate('/home'); }}
                            src="/logo.jpeg"
                            alt="logo"
                            style={{
                                width: 30,
                                height: 30,
                                marginRight: 10,
                                borderRadius: 50,
                                cursor: 'pointer',
                            }}
                        />
                        {isLogin && streakState.status === 200 && experienceState.status === 200 && coursesState.status === 200 && coursesState.data.courses.length > 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                {isLogin && isAdmin &&
                                    <CreateCourse
                                    />
                                }
                                {isAdmin &&
                                    <IconButton
                                        onClick={() => {
                                            navigate('/admin/overview');
                                        }}
                                    >
                                        {getCurrentPage() === 'admin'
                                            ? <Settings
                                                sx={{
                                                    fontSize: 15,
                                                }}
                                            />
                                            : <SettingsOutlined
                                                sx={{
                                                    fontSize: 15,
                                                }}
                                            />
                                        }
                                    </IconButton>
                                }
                                <IconButton
                                    onClick={() => {
                                        changetheme();
                                    }}
                                    sx={{
                                        color: useTheme().palette.text.primary,
                                    }}
                                >
                                    {gettheme()
                                        ? <DarkModeRounded
                                            sx={{
                                                fontSize: 15,
                                            }}
                                        />
                                        : <WbSunnyRounded
                                            sx={{
                                                fontSize: 15,
                                            }}
                                        />
                                    }
                                </IconButton>
                                <Button
                                    variant="text"
                                    sx={{
                                        color: streakState.data.streak > 0 ? 'primary.dark' : useTheme().palette.text.primary,
                                    }}
                                >
                                    <Whatshot
                                        sx={{
                                            marginRight: 1,
                                        }}
                                    />
                                    {streakState.data.streak}
                                </Button>
                                <Button
                                    variant="text"
                                    sx={{
                                        color: experienceState.data.experience > initialExperience ? 'primary.dark' : useTheme().palette.text.primary,
                                    }}
                                    onClick={() => {
                                        navigate('/leaderboard');
                                    }}
                                >
                                    <AutoGraph
                                        sx={{
                                            marginRight: 1,
                                        }}
                                    />
                                    {experienceState.data.experience}
                                </Button>
                                <BetterCourseSwitcher
                                    course={course}
                                    setCourse={setCourse}
                                />
                            </Box>
                        }
                        {!isLogin &&
                            <Select
                                variant="standard"
                                value={language}
                                onChange={(event) => setLanguage(event.target.value)}
                                sx={{
                                    ml: 2,
                                }}
                            >
                                <MenuItem value="english">English</MenuItem>
                                <MenuItem value="german">Deutsch</MenuItem>
                                <MenuItem value="french">Français</MenuItem>
                                <MenuItem value="spanish">Español</MenuItem>
                                <MenuItem value="italian">Italiano</MenuItem>
                                <MenuItem value="dutch">Nederlands</MenuItem>
                                <MenuItem value="portuguese">Português</MenuItem>
                            </Select>
                        }
                        {experienceState.status !== 102 && experienceState.userState.isLogin === false &&
                            <NewLoginButton
                                translations={translations}
                            />
                        }
                    </Toolbar>
                </AppBar>
            }
            {!isMobile &&
                <Box
                    sx={{
                        height: 82.5,
                        width: '100%',
                    }}
                >
                </Box>
            }
            {props.children}
            {isMobile && !props.noMenu && isLogin &&
                <Box
                    sx={{
                        height: 94,
                        width: '100%',
                    }}
                >
                </Box>
            }
            {isMobile && !props.noMenu && isLogin &&
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: 1,
                        height: 'auto',
                        minHeight: 75,
                        paddingTop: 0.2,
                        paddingBottom: 2,
                        display: { xs: 'block', md: 'none' },
                        zIndex: 1003,
                        borderTop: '1px solid #e0e0e0',
                        color: 'text.secondary',
                        backgroundColor: useTheme().palette.primary.dark + (gettheme() ? '50' : '20'),
                        backdropFilter: 'blur(15px)',
                    }}
                >
                    <BottomNavigation
                        sx={{
                            backgroundColor: 'transparent',
                        }}
                        showLabels
                    >
                        <BottomNavigationAction
                            label={translations.lections}
                            icon={getCurrentPage() === 'lections' ? <Home /> : <HomeOutlined />}
                            onClick={() => {
                                navigate('/home');
                            }}
                        />
                        <BottomNavigationAction
                            label={translations.vocab}
                            icon={
                                <>
                                    {getCurrentPage() === 'vocab' ? <StickyNote2 /> : <StickyNote2Outlined />}
                                    <Badge
                                        badgeContent={phasesCountState.data.dueCount}
                                        color="error"
                                        sx={{
                                            position: 'absolute',
                                            top: 11,
                                            left: '60%',
                                        }}
                                    >
                                    </Badge>
                                </>
                            }
                            onClick={() => {
                                navigate('/vocab/' + translateCourseIntoBaseLanguage(course) + '/' + translateCourseIntoTargetLanguage(course));
                            }}
                        />
                        {lectionsState.status === 200 && lectionsState.data.letters.length > 0 &&
                            <BottomNavigationAction
                                label={translations.alphabet}
                                icon={getCurrentPage() === 'alphabet' ? <SpatialAudio /> : <SpatialAudioOutlined />}
                                onClick={() => {
                                    navigate('/alphabet');
                                }}
                            />
                        }
                        {experienceState.status === 200 && experienceState.data.personalLeaderboard.xp > 0 &&
                            <BottomNavigationAction
                                label={translations.leaderboard}
                                icon={getCurrentPage() === 'leaderboard' ? <Leaderboard /> : <LeaderboardOutlined />}
                                onClick={() => {
                                    navigate('/leaderboard');
                                }}
                            />
                        }
                        <BottomNavigationAction
                            label={translations.profile}
                            icon={getCurrentPage() === 'profile' ? <Person /> : <PersonOutlined />}
                            disabled
                            onClick={() => {
                                navigate('/profile');
                            }}
                        />
                    </BottomNavigation>
                </Box>
            }
        </div>
    );
}

